<template>
  <base-section id="aide-memoire">
    <base-section-heading title="Aide-mémoire">
      Vous trouverez ici une liste d'informations importantes concernant les documents nécessaires pour une gestion efficace de vos dossiers lors de la saison des impôts. N'hésitez-pas à entrer en contact avec moi si vous avez des interrogations sur un ou l'autre de ces documents.
    </base-section-heading>

    <v-container>
      <v-row align="center">

        <v-expansion-panels inset>
          <v-expansion-panel
            v-for="(item,i) in sections"
            :key="i"
          >
            <v-expansion-panel-header><h3>{{ item.title }}</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ item.description }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'AideMemoire',

    data: () => ({
      sections: [
        {
          title: 'Avis de cotisation de l’année précédente',
          description: 'Quelques semaines après la production de vos déclarations de revenus, l’Agence du Revenu du Canada et Revenu Québec vous envoient chacun un avis de cotisation. Ces documents renferment parfois des renseignements importants. Bien que non obligatoires, il est fortement suggéré de les conserver et de me les fournir.',
        },
        {
          title: 'Revenus d’emploi (salaire)',
          description: 'T4 / Relevé 1',
        },
        {
          title: 'Revenus de pension et autres revenus',
          description: 'T4A / Relevé 2 ou Relevé 1',
        },
        {
          title: 'Assurance-emploi',
          description: 'T4E',
        },
        {
          title: 'Prestations d’urgence liées à la pandémie de COVID-19',
          description: 'Pour toutes questions liées à ces prestations, n’hésitez pas à me contacter.',
        },
        {
          title: 'Aide-sociale ou CNESST',
          description: 'T5007 / Relevé 5',
        },
        {
          title: 'Prêts et bourses',
          description: 'T4A / Relevé 1',
        },
        {
          title: 'Revenus ou retraits REER',
          description: 'T4RSP / Relevé 2',
        },
        {
          title: 'Cotisations REER',
          description: 'Feuillets de cotisation (mars à décembre de l’année d’imposition et 60 premiers jours de l’année courante)',
        },
        {
          title: 'Cotisations REER de FTQ ou CSN',
          description: 'Relevé 10 (mars à décembre de l’année d’imposition et 60 premiers jours de l’année courante)',
        },
        {
          title: 'Frais de garde d’enfant',
          description: 'Relevé 24 et autres reçus',
        },
        {
          title: 'Versements anticipés des frais de garde d’enfant',
          description: 'Relevé 19',
        },
        {
          title: 'Activités physiques et artistiques des enfants',
          description: 'Tous les reçus. Certaines de ces dépenses sont déductibles. Assurez-vous de conserver les reçus et de me les fournir.',
        },
        {
          title: 'Frais médicaux (médicaments, lunettes, dents, physio, chiro, etc.)',
          description: 'Tous les reçus. Certains frais médicaux, tels les médicaments d’ordonnance, les frais pour la vue et les frais de dentiste peuvent donner droit à un crédit d’impôt.',
        },
        {
          title: 'Frais de scolarité',
          description: 'T2202A et Relevé 8. Les frais de scolarité postsecondaire donnent droit à un crédit d’impôt reportable indéfiniment. Il est très important de me fournir ces documents à chaque année afin de les utiliser ou de les reporter, selon le cas.',
        },
        {
          title: 'Intérêts sur prêt étudiant',
          description: 'Relevé banquaire. Les intérêts payés sur certains prêts étudiants sont admissibles à un crédit d’impôt.',
        },
        {
          title: 'Cotisation à un ordre professionnel',
          description: 'Reçu de la cotisation émis par votre ordre. Les cotisations obligatoires à un ordre professionnel sont déductibles.',
        },
        {
          title: 'Habitation',
          description: 'Relevé 31 si vous êtes locataire, compte de taxes municipales si vous êtes propriétaire. Si vous avez fait l’achat d’une première habitation durant l’année, vous pourriez être admissible à un crédit d’impôt.',
        },
      ]
    }),
  }
</script>

<style scoped>
.aide-memoire-title {
  color: red;
}
</style>